
const Buttons: { [key: string]: any } = {
  confirm: { text: "確認", color: "warning", click: "clickConfirm", icon: "" },
  previous: {
    text: "戻る",
    color: "blue darken-2 white--text",
    click: "clickPrevious",
    icon: ""
  },
  close: {
    text: "閉じる",
    color: "grey",
    click: "clickClose",
    icon: "mdi-close"
  },
  no: {
    text: "いいえ",
    color: "grey",
    click: "clickNo",
    icon: ""
  },
  yes: { text: "はい", color: "success", click: "clickYes", icon: "" },
  set: {
    text: "設定",
    color: "info",
    click: "clickSet",
    icon: ""
  },
  complete: {
    text: "完了",
    color: "success",
    click: "clickComplete",
    icon: ""
  },
  del: {
    text: "削除",
    color: "error",
    click: "clickDelete",
    icon: "mdi-delete"
  },
  next: {
    text: "次へ",
    color: "success",
    click: "clickNext",
    icon: "mdi-chevron-right"
  },
  apply: {
    text: "反映",
    color: "success",
    click: "clickapply",
    icon: "mdi-archive-refresh"
  }
};

import Vue from "vue";
export default Vue.extend({
  name: "DialogBase",
  props: {
    //Visible
    visible: Boolean,
    value: Boolean,
    fullscreen: Boolean,
    //Header
    title: String,
    headerColor: { type: String, default: "primary" },
    headerTextColor: { type: String, default: "white" },
    icon: String,
    headerButtonDisp: Boolean,
    //Size
    width: [String, Number],
    maxWidth: [String, Number],
    height: [String, Number],
    maxHeight: [String, Number],
    //fixed form
    xSmall: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    xLarge: Boolean,
    //Footer
    confirm: Boolean,
    previous: Boolean,
    close: Boolean,
    no: Boolean,
    yes: Boolean,
    complete: Boolean,
    set: Boolean,
    del: Boolean,
    next: Boolean,
    apply: Boolean,
    disableClose: Boolean,
    disableComplete: Boolean,
    disableYes: Boolean,
    disableNo: Boolean,
    disableDelete: Boolean,
    disableNext: Boolean,
    disablePrevious: Boolean,
    disableConfirm: Boolean,
    transition: String
  },
  data() {
    return {};
  },
  computed: {
    isButtons(): boolean {
      return Object.keys(Buttons).some(key => !!this.$props[key]);
    },
    Buttons(): object {
      const buttons: { [key: string]: object } = {};
      const props = this.$props;

      for (const key in Buttons) {
        const button = { ...Buttons[key] };
        const prop = props[key];
        if (prop) {
          button.visible = prop[key];
          button.disabled = prop["disable" + key];

          buttons[key] = button;
        }
      }

      return buttons;
    }
  },
  methods: {
    getWidth() {
      let width;
      if (this.maxWidth) {
        width = this.maxWidth;
      } else if (this.xLarge) {
        width = 1400;
      } else if (this.large) {
        width = 1000;
      } else if (this.medium) {
        width = 800;
      } else if (this.small) {
        width = 500;
      } else if (this.xSmall) {
        width = 290;
      } else {
        width = null;
      }
      return width;
    }
  }
});
